import * as React from "react";
import HcpLayout from "../../components/hcp/layout";
import { Seo } from "../../components/seo";
import "./about-wakix.scss";
import bannerImg from "../../images/why-wakix/hero-about.webp";
import bannerImgMobile from "../../images/why-wakix/hero-about-mobile.webp";
import bannerBlock from "../../images/why-wakix/cta-what-you-expect.webp";
import bannerBlockMobile from "../../images/why-wakix/cta-what-you-expect-mobile.webp";
// import Lindsey from "../../images/why-wakix/lindsey-thumb.webp";
// import LindseyName from "../../images/why-wakix/lindsey-name.svg";
// import icon_talk from "../../images/why-wakix/personalize-discussion-icon.svg";
import { TfiAngleRight } from "react-icons/tfi";
import { Link } from "gatsby";
import SocialIconBlock from "../../components/socialIconBlock";
import { Row, Col } from "react-grid-system";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazy-load";
import taltToHcpImg from "../../images/why-wakix/talk-to-hcp-cta.webp";
import taltToHcpMobImg from "../../images/why-wakix/talk-to-hcp-cta-mobile.webp";
import useDeviceSize from "../../hooks/use-device-size";
import InnerBanner from "../../components/InnerBanner";

const AboutWakix = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout pageClass="about-page">
            <InnerBanner
                topHeading="Why WAKIX?"
                bannerClass="about"
                bannerHeroImg={bannerImg}
                bannerHeroImgMobile={bannerImgMobile}
                bannerImgAlt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with friends"
                heroNameImg={
                    <StaticImage
                        src="../../images/why-wakix/hero-about-name.webp"
                        alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
                        width={347}
                        height={138}
                        quality={95}
                        layout="fixed"
                        placeholder="none"
                        style={{ transition: "opacity 0.5s" }}
                        imgStyle={{ opacity: 1 }}
                    />
                }
            />

            <section className="inner-first">
                <div className="centered-content">
                    <h1 className="font-40">Why WAKIX?</h1>
                </div>
            </section>

            <section>
                <div className="centered-content">
                    <div className="section-card_items">
                        <ul className="icon-list">
                            <li>
                                <div className="icon">
                                    {deviceSizes?.smUp ? (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-first-class.svg"
                                            alt="First-of-its-kind icon"
                                            placeholder="none"
                                            quality={95}
                                            layout="constrained"
                                            style={{
                                                transition: "opacity 0.5s",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    ) : (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-first-class-mb.webp"
                                            alt="First-of-its-kind icon"
                                            placeholder="none"
                                            quality={95}
                                            layout="constrained"
                                            style={{
                                                transition: "opacity 0.5s",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    )}
                                </div>
                                <div className="text">
                                    <h3>First-of-its-kind</h3>
                                    <span>
                                        WAKIX is a{" "}
                                        <strong>
                                            first-of-its-kind medication
                                        </strong>{" "}
                                        that increases{" "}
                                        <strong>histamine</strong> levels in the
                                        brain.
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    {deviceSizes?.smUp ? (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-once-daily.svg"
                                            alt="Once daily calendar icon"
                                            quality={95}
                                            layout="constrained"
                                            placeholder="none"
                                            style={{
                                                transition: "opacity 0.5s",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    ) : (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-once-daily-mb.webp"
                                            alt="Once daily calendar icon"
                                            quality={95}
                                            layout="constrained"
                                            placeholder="none"
                                            style={{
                                                transition: "opacity 0.5s",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    )}
                                </div>
                                <div className="text">
                                    <h3>Once-daily tablet medication</h3>
                                    <span>
                                        WAKIX is a{" "}
                                        <strong>
                                            once-daily tablet medication taken
                                            in the morning
                                        </strong>
                                        , as soon as you wake up.
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div
                                    className="icon"
                                    style={{ textAlign: "center" }}
                                >
                                    {deviceSizes?.smUp ? (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-not-controlled.svg"
                                            alt="Not a controlled substance pill bottle icon"
                                            placeholder="none"
                                            quality={95}
                                            layout="constrained"
                                            style={{
                                                transition: "opacity 0.5s",
                                                maxWidth: 91,
                                                marginTop: "-15px",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    ) : (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-not-controlled-mb.webp"
                                            alt="Not a controlled substance pill bottle icon"
                                            placeholder="none"
                                            quality={95}
                                            layout="constrained"
                                            style={{
                                                transition: "opacity 0.5s",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    )}
                                </div>

                                <div className="text">
                                    <h3>
                                        Not a{" "}
                                        <span className="tooltip green">
                                            controlled substance
                                            <span className="tooltip-text">
                                                A drug or chemical that is
                                                regulated by the government
                                                based on its potential for abuse
                                                and dependence.
                                            </span>
                                        </span>
                                    </h3>
                                    <span>
                                        WAKIX is the first and only{" "}
                                        <nobr>FDA-approved</nobr> treatment for
                                        people with narcolepsy that is{" "}
                                        <strong className="italic">not</strong>
                                        <strong> a controlled substance</strong>
                                        .
                                    </span>
                                    <ul className="blueDot-list left-indent">
                                        <li>
                                            In a clinical study in adults, WAKIX
                                            did not show potential for abuse,
                                            similar to placebo (sugar pill).
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    {deviceSizes?.smUp ? (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-not-a-stimulant.svg"
                                            alt="Not a stimulant icon"
                                            placeholder="none"
                                            quality={95}
                                            layout="constrained"
                                            style={{
                                                transition: "opacity 0.5s",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    ) : (
                                        <StaticImage
                                            src="../../images/why-wakix/why-wakix-icons-not-a-stimulant-mb.webp"
                                            alt="Not a stimulant icon"
                                            placeholder="none"
                                            quality={95}
                                            layout="constrained"
                                            style={{
                                                transition: "opacity 0.5s",
                                            }}
                                            imgStyle={{ opacity: 1 }}
                                        />
                                    )}
                                </div>
                                <div className="text">
                                    <h3>Not a stimulant</h3>
                                    <span>
                                        WAKIX is{" "}
                                        <strong className="italic">not</strong>
                                        <strong> a stimulant</strong>, so the
                                        way your body feels when taking WAKIX
                                        may be different from medications you
                                        have taken in the past.
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <StaticImage
                                        src="../../images/why-wakix/no-significant-drug-interactions-icon.svg"
                                        alt="No significant drug interactions with sodium oxybate or modafinil icon"
                                        placeholder="none"
                                        quality={95}
                                        layout="constrained"
                                        style={{
                                            transition: "opacity 0.5s",
                                        }}
                                        imgStyle={{ opacity: 1 }}
                                    />
                                </div>
                                <div className="text">
                                    <h3>
                                        No significant drug interactions with
                                        sodium oxybate or modafinil
                                    </h3>
                                    <span>
                                        In a clinical study in adults where
                                        WAKIX was taken with sodium oxybate or
                                        modafinil, there were{" "}
                                        <strong>no significant effects</strong>{" "}
                                        on the levels of medications in the
                                        body.
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="guidance-block bg-cover">
                {deviceSizes?.smUp ? (
                    <LazyLoad offset={300} debounce={false}>
                        <img
                            src={taltToHcpImg}
                            alt="Chris discussing WAKIX with his healthcare provider"
                        />
                    </LazyLoad>
                ) : (
                    <LazyLoad offset={300} debounce={false}>
                        <img
                            src={taltToHcpMobImg}
                            alt="Chris discussing WAKIX with his healthcare provider"
                        />
                    </LazyLoad>
                )}
                <div className="centered-content">
                    <Row className="row">
                        <Col lg={4} md={12} order={{ xs: 2, lg: 1 }}>
                            {/* <LazyLoad offset={300} debounce={false}>
                <img
                  src={icon_talk}
                  className="banner-mobile-device"
                  alt="Personalized conversation plan icon"
                />
              </LazyLoad> */}
                        </Col>
                        <Col
                            lg={8}
                            md={12}
                            order={{ xs: 1, lg: 2 }}
                            className="content-block"
                        >
                            <h2>
                                <span className="asap-bold">
                                    Use this personalized discussion guide when
                                    you're ready to talk to your healthcare
                                    provider about WAKIX
                                </span>
                            </h2>
                            <p>
                                If you're an adult newly diagnosed with
                                narcolepsy or diagnosed for years, use this
                                personalized discussion guide to talk to your
                                healthcare provider about how WAKIX might help
                                manage your EDS or cataplexy.
                            </p>

                            <div className="btn-wrap">
                                <Link
                                    to="/discussion-guide/personalize"
                                    className="btn btn-primary btn-primary--md"
                                >
                                    Get started <TfiAngleRight />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="banner-block two-columns no-pt no-pb">
                <div className="col bg-green">
                    <div className="inner cta-flt-right">
                        <div className="inner-content">
                            <div className="show-desktop moa-patient-brain-img">
                                <StaticImage
                                    src="../../images/why-wakix/wakix-moa-patient-brain-img.webp"
                                    placeholder="none"
                                    alt="WAKIX MOA icon"
                                    className="moa-patient-img"
                                />
                            </div>
                            <div className="text">
                                <h3 className="h3-spacer asap">
                                    How does WAKIX work?
                                </h3>
                                <div className="show-mobile">
                                    <StaticImage
                                        src="../../images/why-wakix/wakix-moa-patient-brain-img.webp"
                                        placeholder="none"
                                        alt="WAKIX MOA icon"
                                        className="moa-patient-img"
                                    />
                                </div>
                                <div className="btn-wrap">
                                    <Link
                                        to="/about-wakix/how-does-wakix-work"
                                        className="btn btn-primary btn-primary--md"
                                    >
                                        See now
                                        <TfiAngleRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col bg-img">
                    <div className="inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerBlock}
                                className="img-flip"
                                alt="Kristen discussing WAKIX with her healthcare provider"
                            />
                        ) : (
                            <img
                                src={bannerBlockMobile}
                                alt="Kristen discussing WAKIX with her healthcare provider"
                            />
                        )}
                        <div className="content cout-left-spacer">
                            <h3>
                                What to expect
                                <br /> with WAKIX
                            </h3>
                        </div>
                        <div className="btn-wrap cout-left-spacer btn-wrap-align-left">
                            <Link
                                to="/starting-wakix/what-to-expect"
                                className="btn btn-primary btn-primary--md"
                            >
                                Find out
                                <TfiAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <SocialIconBlock />
        </HcpLayout>
    );
};

export default AboutWakix;

export const Head = () => (
    <Seo
        title="Why WAKIX® (pitolisant)?"
        description="WAKIX is FDA approved to treat excessive daytime sleepiness (EDS) or cataplexy in adults with narcolepsy and to treat EDS in children 6 years & older with narcolepsy"
        keywords="why WAKIX, narcolepsy treatment, narcolepsy medication, treatment for narcolepsy, cataplexy treatment, excessive daytime sleepiness treatment"
    />
);
